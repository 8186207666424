import { Action } from '@ngrx/store';
// import {
//   AuthorizationRequest,
//   UserDetail,
// } from '@melloops-dashboard/domain-models';

export enum AuthActionTypes {
  VerifyToken = '[AUTH] Verify Token',
  VerifyTokenSuccess = '[AUTH] Verify Token Success',
  VerifyTokenFailure = '[AUTH] Verify Token Failure',
  ReloadAuthState = '[AUTH] Reload Auth State',
  ResetError = '[AUTH] Reset Error',
}

export class VerifyToken implements Action {
  readonly type = AuthActionTypes.VerifyToken;
  constructor(public payload: { request: any }) {}
}

export class VerifyTokenSuccess implements Action {
  readonly type = AuthActionTypes.VerifyTokenSuccess;
  constructor(public payload: { result: any }) {}
}

export class VerifyTokenFailure implements Action {
  readonly type = AuthActionTypes.VerifyTokenFailure;
  constructor(public payload: { error: any }) {}
}

export class ReloadAuthState implements Action {
  readonly type = AuthActionTypes.ReloadAuthState;
  constructor(public payload: { authState: any }) {}
}
export class ResetError implements Action {
  readonly type = AuthActionTypes.ResetError;
}

export type AuthActions =
  | VerifyToken
  | VerifyTokenSuccess
  | VerifyTokenFailure
  | ResetError
  | ReloadAuthState;
