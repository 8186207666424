<div class="app-l-loading-wrap">
  <div class="app-l-login-loading">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    <mat-spinner
      color="accent"
      value="100"
      mode="indeterminate"
      diameter="30"
    ></mat-spinner>
  </div>
</div>
