import { Injectable } from '@angular/core';
import { BrowserStorageService, StorageKey} from '@wlms-web/utils';
import * as moment from 'moment';
import { AuthService } from './auth.service';
import { timer, Subscription } from 'rxjs';

@Injectable()
export class AuthHelperService {


  refreshTokenTimer: Subscription;
  tokenOffsetTimeLimit = 180000;
  tokenOffsetTime = 120000;
  constructor(
    private browserStorageService: BrowserStorageService,
    public authService: AuthService
  ) { }

  getAuthUserDetails() {
    const loggedUser = this.getAuthUser();
    if (loggedUser && loggedUser.IsValid) {
      return loggedUser;
    }
    return null;
  }

  clearOutSession() {
    this.browserStorageService.clearLocalStorage();
  }

  tokenExpiresIn() {
    const loggedUser = this.getAuthUser();
    let difference = 0;
    if (loggedUser && loggedUser.IsValid) {
      const tokenExpiry = moment(loggedUser.TokenExpiry);
      if (tokenExpiry) {
        difference = tokenExpiry.diff(moment());
      }
    }
    return difference;
  }

  updateTokenDetails(token: string, expiryTime: string) {
    const authUser = this.getAuthUser();
    if (authUser && authUser.IsValid) {
      authUser.AuthToken = token;
      authUser.TokenExpiry = expiryTime;
      this.setAuthUser(authUser);
    }
  }

  verifyRefreshToken(authUser: any, remainingTime: number) {
    if (remainingTime > this.tokenOffsetTimeLimit) {
      this.setTimer(remainingTime);
    } else {
      this.refreshToken(authUser);
    }
  }

  setTimer(expiryIn: number) {
    const timerValue = this.calculateTokenExpiresIn(expiryIn);
    const refreshToken = timer(timerValue);
    this.refreshTokenTimer = refreshToken.subscribe(() => {
      const authUser = this.getAuthUserDetails();
      this.refreshToken(authUser);
    });
  }

  stopTimer() {
    if (this.refreshTokenTimer) {
      this.refreshTokenTimer.unsubscribe();
    }
  }

  refreshToken(authUser: any) {
    const token = authUser ? authUser.AuthToken : null;
    const result = this.authService.refreshToken(token);
    result.subscribe((response: any) => {
      const newToken = response.details;
      this.updateTokenDetails(newToken.Token, newToken.Expires);
      const expiryIn = this.tokenExpiresIn();
      this.setTimer(expiryIn);
    });
  }

  azureLoggedInUserTokenExpiry() {
    const loggedInUser = this.browserStorageService.getLocalStorageValue(StorageKey.AZURELOGGEDINUSER);
    let difference = 0;
    if (loggedInUser && loggedInUser.IsValid) {
      const tokenExpiry = moment(loggedInUser.TokenExpiry);
      if (tokenExpiry) {
        difference = tokenExpiry.diff(moment());
      }
    }
    return difference;
  }

  private getAuthUser() {
    return this.browserStorageService.getLocalStorageValue(StorageKey.AZURELOGGEDINUSER);
  }

  private setAuthUser(authUser: any) {
    return this.browserStorageService.setLocalStorageValue(StorageKey.AZURELOGGEDINUSER, authUser);
  }

  private calculateTokenExpiresIn(expiryIn: number) {
    let expiresIn = 0;
    if (expiryIn > this.tokenOffsetTimeLimit) {
      expiresIn = expiryIn - this.tokenOffsetTime;
    }
    return expiresIn;
  }
}
