import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { BrowserStorageService, StorageKey, ConfigKey, ConfigService, SharedService, ScreenActionPrivileges } from '@wlms-web/utils'
import { UiHelperService } from '@wlms-web/ui-kit';
@Component({
  selector: 'wlms-web-sliding-menu',
  templateUrl: './sliding-menu.component.html',
  styleUrls: ['./sliding-menu.component.scss'],
})
export class SlidingMenuComponent implements OnInit {
  searchPanel = false;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  loginDetails: any;
  userDetails: any;
  isAuthorised = false;
  screenActionPrivileges = ScreenActionPrivileges;

  constructor(private router: Router,
    private browserStorageService: BrowserStorageService,
    private sharedService: SharedService,
    private appConfigService: ConfigService,
    private uiHelperService: UiHelperService) { }

  ngOnInit(): void {
    this.loginDetails = this.browserStorageService.getLocalStorageValue(StorageKey.LOGININFO);
    this.userDetails = this.browserStorageService.getLocalStorageValue(StorageKey.AZURELOGGEDINUSER);
  }

  isAuthUser(param) {
    return this.sharedService.isAuthUser(param);
  }

  loadPage(page) {
    switch (page) {
      case 'warehouseLineList':
        this.router.navigateByUrl('/warehouselines');
        break;
      case 'loanAssignment':
        this.router.navigateByUrl('/loan-assignment');
        break;
        case 'exporthistory':
          this.router.navigateByUrl('/export-history');
          break;
        
      
    }
  }

  signOut() {
    const tenantId = this.loginDetails?.RequestParameters?.tenantId;
    this.browserStorageService.clearLocalStorage();
    let logOutUrl = this.appConfigService.getAppConfiguration().logOutUrl;
    if (tenantId) {
      logOutUrl = logOutUrl.replace(ConfigKey.LOGOUTTENANTIDKEY, tenantId);
      logOutUrl = logOutUrl.replace(
        ConfigKey.LOGOUTREDIRECTURIKEY,
        window.location.origin
      );
      window.location.href = logOutUrl;
    } else {
      this.router.navigate(['/']);
    }
    this.browserStorageService.clearSessionStorage();
  }

  

 
}
