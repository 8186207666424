import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthHelperService } from '../services/auth-helper.service';
import { AuthService } from '../services/auth.service';
import { AuthFacade } from '../+state/auth.facade';
import { SharedService, } from '@wlms-web/utils';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  verifiedUser$: Subscription;
  userDetails: any;
  timerSubscription: Subscription;
  constructor(
    public router: Router,
    public authHelperService: AuthHelperService,
    public authService: AuthService,
    private authFacade: AuthFacade,
    private sharedService: SharedService,
    private route: ActivatedRoute
  ) { }
  canActivate(route): UrlTree | boolean {
    const authUser = this.authHelperService.getAuthUserDetails();
    const remainingTime = this.authHelperService.tokenExpiresIn();
    const privilegekey = route.data['privilegeKey'] ;
    const isPrivilegedUser = privilegekey ? this.sharedService.isAuthUser(privilegekey) : true;
    if (authUser && remainingTime > 0 ) {
      if(isPrivilegedUser){
        this.authHelperService.verifyRefreshToken(authUser, remainingTime);
        this.reloadAuthState();
        return true;
      }else{
        const type = 'UnAuthorized';
        this.router.navigate(['/exitPage', type]);
      }
    } else {
      this.authHelperService.clearOutSession();
      return this.router.parseUrl('/');
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }

  reloadAuthState() {
    const authUser = this.authHelperService.getAuthUserDetails();
    this.authFacade.reloadAuthState(authUser);
  }
}
