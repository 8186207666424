import { NgModule, APP_INITIALIZER, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { AppComponent } from './app.component';
import { CoreModule } from '@wlms-web/core';
import { APP_ROUTES } from './app.routes';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppConfigLoader, ConfigService } from '@wlms-web/utils';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { AuthGuard, DeactivateGuard } from '@wlms-web/data-access';
import { FormsModule } from '@angular/forms';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScullyLibModule } from '@scullyio/ng-lib';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    TimepickerModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule.forRoot(APP_ROUTES),
    CoreModule,
    FormsModule,
    AgGridModule.withComponents([]),
    BrowserAnimationsModule,
    FormsModule,
    MatProgressSpinnerModule,
    ScullyLibModule
  ],
  providers: [
    ConfigService,
    DatePipe,
    AuthGuard,
    DeactivateGuard,
    CurrencyPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: AppConfigLoader,
      deps: [ConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent], 
})
export class AppModule {
  constructor(private ngZone: NgZone) {
    (window as any).ngZone = this.ngZone;
  }
}
