import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  VerifyToken,
  VerifyTokenSuccess,
  VerifyTokenFailure,
  AuthActionTypes,
  AuthActions,
} from './auth.actions';
import { AuthService } from '../services/auth.service';
import { mapUserDetails } from '@wlms-web/utils';

@Injectable()
export class AuthEffects {
  @Effect()
  loadAuths$ = this.actions$.pipe(
    ofType<VerifyToken>(AuthActionTypes.VerifyToken),
    map((action: VerifyToken) => action.payload),
    mergeMap(({ request }) => this.authService.verifyToken(request)),
    map(
      (result: any) =>
        new VerifyTokenSuccess({ result: mapUserDetails(result.details) })
    ),
    catchError((error) => of(new VerifyTokenFailure({ error })))
  );

  constructor(
    private actions$: Actions<AuthActions>,
    private authService: AuthService
  ) {}
}
