import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService, ApiConfig } from '@wlms-web/utils';
import { BaseApiService } from '@ldsm/utils';
import { ApiResponse } from '../loan-assignment/models/ApiResponse';
import { WarehouseLine } from '../loan-assignment/models/WarehouseLine';
@Injectable({
  providedIn: 'root'
})
export class LoanAssignmentService extends BaseApiService {

  constructor(protected app: ConfigService, protected http: HttpClient) {
    super(
      http,
      app.getAppConfiguration().apiBaseUrl,
      app.getAppConfiguration().apiNamespace,
      ''
    );
  }

  getLoans(los, testMode) {
    return this.get('@fundingService/LoansReadyForFunding?LoanOriginationSystems=' + los + "&TestMode=" + testMode, ApiConfig.DISABLELOANCACHE);
  }
  
  assignLoan(request) {
    return this.put('@fundingService/LoanWarehouseAssignment', request);
  }

  pushBackToFundingQueue(loans: any, los) {
    return this.post('Loan/' + los + '/PushBackToFundingQueue', loans);
  }

  markReviewLoan(request: any) {
    return this.put('@fundingService/LoanReviewed', request);
  }
  
  updateLoanException(request: any) {
    return this.put('@fundingService/LoanException', request);
  }
  
  searchLoan(loanNumber: string, los, testMode) {
    // return this.get('Loan/' + los + '/SearchLoan/' + loanNumber, ApiConfig.DISABLESEARCHLOANCACHE);  
    return this.get('@fundingService/LoanLookup?LoanOriginationSystem=' + los + '&LoanNumber=' + loanNumber + '&TestMode=' + testMode, ApiConfig.DISABLESEARCHLOANCACHE);
  }
  
  updateAdvanceDate(request, los) {
    return this.post('Loan/' + los + '/UpdateAdvanceDate', request);
  }

  getWarehouseline(los) {
    return this.get<ApiResponse<WarehouseLine[]>>('@warehouselineServiceUrl/WarehouseLineDetailForPipeline?LoanOriginationSystems=' + los, ApiConfig.DISABLEGETTIMECACHE);
  }

  getPstCurrentDate() {
    return this.get('AppCommon/CurrentPstDateTime', ApiConfig.DISABLEGETTIMECACHE);
  }

  getAggregates() {
    return this.get('StaticFiles/Json/getAggregates.json');
  }

  assignAndExportLoan(request) {
    //return this.post('Export/' + los +'/AssignandExport', request);
    return this.post('@fundingService/ExportLoansForFunding', request);
  }
 
  reprocessLoanUpdates(request) {
    // return this.post('Export/' + los +'/ReprocessLoanUpdates/');
    return this.put('@fundingService/ReprocessFailedLoans', request);
  }

  saveUserPreferences(request){
    return this.post('user/SaveUserPreference', request);
  }

  getUserPreferences(){
    return this.get('user/GetPreference/', ApiConfig.DISABLELOANCACHE);
  }

  getColumnMaster(id){
    return this.get('user/GetUserPrefernceColumnMaster/'+id);
  }

  ResetUserPreference(request){
    return this.post('user/ResetUserPreference', request);
  }
}
