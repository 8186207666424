import { CurrencyFormatter,DateFormattor } from '../gridutils/grid-utils';
export const ExportHistory = [
  {
    'headerName': '',
    "field": "rowCheck",
    "checkboxSelection": true,
    "headerCheckboxSelection": true,
    "headerCheckboxSelectionFilteredOnly" : true,
    "active": true,
    "width": 40,
    cellStyle: { left: '0px', 'z-index':'0' },
    "pinned": "left",
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressFiltersToolPanel": true,
    "suppressColumnsToolPanel": true,
  },
  {
    "field": "Channel",
    "headerName": "Channel",
    "width": 165,
    "filter": "agSetColumnFilter",
  },
  {
    "field": "LoanNumber",
    "headerName": "Loan Number",
    "width": 180,
    "filter": "agTextColumnFilter",
    detailCategory: 1,
  },  
  {
    "field": "LastName",
    "headerName": "Last Name",
    "width": 180,
    "filter": "agTextColumnFilter",
    detailCategory: 2,
  },
  {
    "field": "DisbursementDate",
    "headerName": "Disbursement Date",
    "cellRenderer": "formatDateRenderer",
    "width": 180,
    detailValueFormatters: ['date'],
    detailCategory: 3,
    "filter": "agDateColumnFilter",
    comparator: DateFormattor.dateComparator
  },
  {
    "field": "WireAmount",
    "headerName": "Wire Amount",
    "width": 180,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    detailValueFormatters: ['currency'],
    detailCategory: 1,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "WarehouseLine",
    "headerName": "Warehouse Line",
    "width": 180,
    "filter": "agTextColumnFilter",
    detailCategory: 2,
  },
  {
    "field": "BatchNumber",
    "headerName": "Batch Number",
    "width": 200,
    "filter": "agTextColumnFilter",
    detailCategory: 2,
  },
];
