export const UpdateAdvanceDate = [
    {
        "field": "LoanNumber",
        "headerName": "Loan Number",
        "filter": "agTextColumnFilter",
        "width": 400,
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    },
    {
        "field": "AdvanceDate",
        "headerName": "Advance Date",
        "width": 400,      
        cellRenderer: 'formatDateRenderer',
        detailValueFormatters: ['date'],
        detailCategory: 3,
        "filter": "agDateColumnFilter",
        suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
      },
]



