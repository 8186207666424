import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import * as AuthSelectors from './auth.selectors';
import { VerifyToken, ResetError, ReloadAuthState } from './auth.actions';
// import {
//   AuthorizationRequest,
//   UserDetail,
// } from '@melloops-dashboard/domain-models';

@Injectable()
export class AuthFacade {
  loading$ = this.store.pipe(select(AuthSelectors.getAuthLoading));
  loaded$ = this.store.pipe(select(AuthSelectors.getAuthLoaded));
  allAuth$ = this.store.pipe(select(AuthSelectors.getAllAuth));
  error$ = this.store.pipe(select(AuthSelectors.getError));
  isValidUser$ = this.store.pipe(select(AuthSelectors.IsValidUser));

  constructor(private store: Store<fromAuth.AuthFullState>) {}

  verifyToken(request: any) {
    this.store.dispatch(new VerifyToken({ request }));
  }

  reloadAuthState(authState: any) {
    this.store.dispatch(new ReloadAuthState({ authState }));
  }

  resetError() {
    this.store.dispatch(new ResetError());
  }
}
