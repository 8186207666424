import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthHelperService } from './services/auth-helper.service';
import { AuthService } from './services/auth.service';

import { StoreModule } from '@ngrx/store';
import { EffectsModule, EffectsFeatureModule } from '@ngrx/effects';
import * as fromAuth from './+state/auth.reducer';
import { AuthEffects } from './+state/auth.effects';
import { AuthFacade } from './+state/auth.facade';
import { EffectsBootstrapProvider } from '@wlms-web/utils'

@NgModule({
  imports: [CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducer),
    EffectsModule.forFeature([]),
    EffectsFeatureModule
  ],
  providers: [AuthHelperService,
    AuthService,
    EffectsBootstrapProvider.provide([AuthEffects]),
    AuthFacade
  ]
})
export class DataAccessModule { }
