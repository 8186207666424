import { Component, OnInit, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoginService } from '../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { BrowserStorageService, StorageKey, SharedService, ScreenActionPrivileges } from '@wlms-web/utils';
import { AuthHelperService, AuthFacade } from '@wlms-web/data-access';

@Component({
  selector: 'wlms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userDetails: any;
  error$: any;
  verifiedUser$: Subscription;
  isAuthenticated: boolean;

  constructor(
    private loginService: LoginService,
    private sharedService: SharedService,
    public route: ActivatedRoute,
    public router: Router,
    private authFacade: AuthFacade,
    private browserStorageService: BrowserStorageService,
    private authHelperService: AuthHelperService
  ) { }

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    event.returnValue = false;
  }

  canDeactivateLogin() {
    this.verifiedUser$.unsubscribe();
    this.error$.unsubscribe();
    this.authFacade.resetError();
    return true;
  }

  //Step 1 : Get Login details for microsoft login
  //Step 2 : After getting the login url, it will be redirected to microsoft login page
  //Step 3 : After successful login , microsoft will redirect to this application with fragment.
  //Step 4 : On receiving the  fragment , the token within the fragment will be validated from API
  //Step 5 : On successful validation , it will be redirected to dashboard

  ngOnInit(): void {
    this.isAuthenticated = false;
    /** To deal with the call back of the custom direcct method */
    this.route.fragment.subscribe((fragment) => {
      if (fragment && fragment !== '') {
        this.isAuthenticated = true;
        const authRequest = this.getVerifyRequestObject(fragment);

        this.authFacade.verifyToken(authRequest);
      }
    });

    this.error$ = this.authFacade.error$.subscribe((value: any) => {
      if (!isEmpty(value)) {
        if (value.error.status === 403) {
          this.authHelperService.clearOutSession();
          this.router.navigate(['/']);
        } if (value.error.status === 401) {
          this.authHelperService.clearOutSession();
          const type = 'UnAuthorized';
          this.router.navigate(['/exitPage', type]);
        } else {
          const type = 'Exception';
          this.router.navigate(['/exitPage', type]);
        }
      }
    });

    // Below we are subscribing to allAuth$ Observable,which is actually accessed through authFacade.
    // When we get the fragment on successful microsoft login,
    // we parse it to a specific JSON Structure(getVerifyRequestObject(fragment: string): AuthorizationRequest),
    // which is then validated from API and it returns token and token expiry,
    // which is used for further communication (Bearer Token) with the API.
    // The response from validateAPI,which comprises of token and token expiry is stored in localStorage(StorageKey.AZURELOGGEDINUSER)
    // and this storage key value is used for the refresh token functionality.

    this.verifiedUser$ = this.authFacade.allAuth$.subscribe((value) => {
      if (value.IsValid && this.isAuthenticated) {
        this.saveToLocalStorage(value);
        this.goToHomePage();
      }
    });

    if (!this.isAuthenticated) {
      const authUser = this.authHelperService.getAuthUserDetails();
      if (authUser) {
        const expiryIn = this.authHelperService.tokenExpiresIn();
        if (expiryIn > 0) {
          this.goToHomePage();
        } else {
          this.authHelperService.clearOutSession();
          this.getLogin();
        }
      } else {
        this.getLogin();
      }
    }
  }

  goToHomePage() {
    if (this.sharedService.isAuthUser(ScreenActionPrivileges.LoanAssignment)) {
      this.router.navigate(['/loan-assignment']);
    } else if (this.sharedService.isAuthUser(ScreenActionPrivileges.Warehouseline)) {
      this.router.navigate(['/warehouselines']);
    }
  }

  getVerifyRequestObject(fragment: string) {
    let verifyRequest: any;
    this.userDetails = Object.assign(
      this.browserStorageService.getLocalStorageValue(StorageKey.LOGININFO)
    );
    const fragmentArray = fragment.split('&');
    fragmentArray.forEach((fragmentObj) => {
      if (fragmentObj && fragmentObj !== '') {
        const fragmentKey = fragmentObj.split('=');
        if (
          fragmentKey &&
          fragmentKey.length > 1 &&
          fragmentKey[0] === 'id_token'
        ) {
          const token = fragmentKey[1].split('.');
          let parseToken;
          if (token && token.length > 1) {
            parseToken = JSON.parse(atob(token[1]));
          }
          const payload = {
            idToken: {
              rawIdToken: fragmentKey[1],
            },
            account: {
              accountIdentifier: parseToken['oid'],
              userName: parseToken['preferred_username'],
            },
          };
          this.userDetails.ObjectId = payload.account.accountIdentifier;
          this.userDetails.EmailAddress = payload.account.userName;
          const requestData: any = {
            Token: payload.idToken.rawIdToken,
            UserLoginDetail: this.userDetails,
            NonValidatingRequestParameters: [],
          };
          verifyRequest = requestData;
        }
      }
    });
    return verifyRequest;
  }

  getLogin(): void {
    this.loginService.getLogin().subscribe(
      (responseObject: any) => {
        const response = responseObject.details;
        this.userDetails = {
          ClientApplication: response.ClientApplication,
          ObjectId: response.UserObjectId,
          EmailAddress: response.EmailAddress,
          RequestParameters: response.RequestParameters,
          Url: response.Url,
          ErrorMessage: response.ErrorMessage,
          IsSuccess: response.IsSuccess
        };
        this.browserStorageService.setLocalStorageValue(
          StorageKey.LOGININFO,
          this.userDetails
        );
        if (responseObject.details.Url) {
          window.location.href =
            responseObject.details.Url +
            '&redirect_uri=' +
            encodeURIComponent(window.location.origin);
        }
      },
      (error) => {
        if (error.status === 403) {
          this.authHelperService.clearOutSession();
          this.router.navigate(['/']);
        } else {
          const type = 'Exception';
          this.router.navigate(['/exitPage', type]);
        }
      }
    );
  }

  saveToLocalStorage(value: any) {
    this.browserStorageService.setLocalStorageValue(StorageKey.AZURELOGGEDINUSER, value);
  }
}
