import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const getAuthState = createFeatureSelector<
  fromAuth.AuthFullState,
  fromAuth.AuthState
>(fromAuth.authFeatureKey);

export const getAuthLoading = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.loading
);

export const getAuthLoaded = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.loaded
);

export const getAuthError = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.error
);

export const getAllAuth = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.authResponse
);

export const getError = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.error
);

export const IsValidUser = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.authResponse.IsValid
);
