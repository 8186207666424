import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageKey, ConfigService, ApiConfig } from '@wlms-web/utils';
import { BrowserStorageService } from '../services/browser-storage.service';
@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  isError: boolean;
  constructor(
    public router: Router,
    private browserStorageService: BrowserStorageService,
    protected app: ConfigService
    ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let requestUrl = request.url;

    if(requestUrl.indexOf('@fundingService') !== -1){
      requestUrl = requestUrl.substring(requestUrl.indexOf('@fundingService'));
      requestUrl = requestUrl.replace('@fundingService', this.app.getAppConfiguration().fundingServiceUrl);
    }
    else if(requestUrl.indexOf('@warehouselineServiceUrl') !== -1){
      requestUrl = requestUrl.substring(requestUrl.indexOf('@warehouselineServiceUrl'));
      requestUrl = requestUrl.replace('@warehouselineServiceUrl', this.app.getAppConfiguration().warehouselineServiceUrl);
    }

    let bearerToken = '';
    if (!request.url.includes('config.json'))
      bearerToken = this.getBearerToken();

    const authReq = request.clone({
      url: requestUrl,
      headers: new HttpHeaders({
        Authorization: bearerToken,
        'Content-Type': 'application/json',
        'x-api-version': request.headers.get('x-api-version') ? request.headers.get('x-api-version') : '1.0'
      }),
    });
    return next.handle(authReq).pipe(
      // catchError((error: HttpErrorResponse) => {
      //   this.exceptionHandlerFacade.loadExceptioHandler(error);
      //   return throwError(error);
      // })
    );
  }

  // Each and every API call after login expects a Bearer Token as part of API Authorization.
  // hence,an interceptor is used to intercept each API request and attach the loggedin user token (StorageKey.AZURELOGGEDINUSER) as valid Bearer token.
  private getBearerToken() {
    const authUser = this.browserStorageService.getLocalStorageValue(StorageKey.AZURELOGGEDINUSER);

    let bearerToken = '';
    if (authUser && authUser.IsValid) {
      bearerToken = `Bearer ${authUser.AuthToken}`;
    }

    return bearerToken;
  }
}
