import { CanDeactivate } from '@angular/router';
import { LoginComponent } from '@wlms-web/features/login';

export class DeactivateGuard implements CanDeactivate<LoginComponent> {
  canDeactivateFlag: boolean;

  canDeactivate(component: LoginComponent): boolean {
    this.canDeactivateFlag = component.canDeactivateLogin();
    return this.canDeactivateFlag;
  }
}
