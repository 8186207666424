export const PushBackResult = [
    {
        "field": "LoanNumber",
        "headerName": "Loan Number",
        "filter": "agTextColumnFilter",
        "width": 150,
        //suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
        
        },
    {
        "field": "Status",
        "headerName": "Status",
        "filter": "agSetColumnFilter",
        "width": 150,
       // suppressSizeToFit:true,
        "suppressMovable": true,
        "suppressSorting": true,
        "suppressFiltersToolPanel": true,
        "suppressColumnsToolPanel": true
    }
]