import { Component, OnInit, OnChanges, AfterViewInit, ViewChild, Input, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import 'ag-grid-enterprise';
import { AgGridAngular } from 'ag-grid-angular';

import { NumericEditorComponent } from './custom/cell-editors/numeric-editor.component';
import { DateEditorComponent } from './custom/cell-editors/date-editor.component';
import { ToggleEditorComponent } from './custom/cell-editors/toggle-editor.component';
import { ActionColumnRendererComponent } from './custom/action-column-renderer/action-column-renderer.component';
import { SharedService, LoanAssignmentLoadingTemplate } from '@wlms-web/utils';
import {
  CustomDateRenderer, ValidationMessageComponent, CustomDateTimeRenderer,
  ColorCellRenderer, IconFormatRendererComponent, ClearFilterHeader
} from './custom/framework-renderers/framework-renderers.component';
import { Overlay } from './custom/overlay/overlay.component';
import * as moment from 'moment-timezone';

@Component({
  selector: 'wlms-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @ViewChild('agGrid') grid: AgGridAngular;
  @ViewChild('gridHidden') gridHidden: AgGridAngular;
  @Output() rowSelectionEvent: EventEmitter<any> = new EventEmitter();
  @Output() filterChangeEvent: EventEmitter<any> = new EventEmitter();
  @Input() paginationPageSize: Number;
  @Input() rowData: Array<String>;
  @Input() isRefresh: boolean;
  @Input() columnDefs: any;
  @Input() noRowsOverlayFlags: any;
  @Output() reviewLoan: EventEmitter<any> = new EventEmitter<any>();
  @Output() editLoan: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() edit: EventEmitter<any> = new EventEmitter<any>();
  @Output() add: EventEmitter<any> = new EventEmitter<any>();
  @Output() undo: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() modalUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowEditStopped: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowEditStarted: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellEditStopped: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellEditStarted: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridReady: EventEmitter<any> = new EventEmitter<any>();
  @Output() noOverlay: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyContract: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyTranche: EventEmitter<any> = new EventEmitter<any>();
  @Output() openWarehouseDetail: EventEmitter<any> = new EventEmitter<any>();

  @Input() blankTemplate: any;
  @Input() loadingTemplate: any
  @Input() treeData: boolean = false;
  @Input() groupDefaultExpanded: Number;
  @Input() getDataPath: any = null;
  @Input() suppressActionPrivilege: any = false;
  @Input() suppressRightClickPrivilege: any = false;
  @Input() suppressFilter: any = false;
  @Input() pagination = true;
  @Input() autoGroupColumnDef: any = {
    headerName: '',
    cellRenderer: 'agGroupCellRenderer',
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true
  };
  @Input() getRowStyle: any
  @Input() editType: any
  @Input() selectedIndices = [];
  @Output() gridEvent: EventEmitter<any> = new EventEmitter();
  @Input() failureMoveLoansIdx: any = [];
  @Input() masterDetail: any = false;
  @Input() detailCellRendererParams: any;
  @Input() isRowSelectable: any = null;
  defaultColDef: any;
  sideBar: any;
  frameworkComponents: any;
  context: any;
  subscription$: Subscription;
  params: any;

  noRowsOverlayComponent: any;
  noRowsOverlayComponentParams: any;
  overlayLoadingTemplate: any;
  private gridApi;
  columnfilterdDefs: any = [];
  userPreferenceChanged = false;
  @Input() getRowClass = params => {
    return 'ag-row';
  };
  currentFilter: any;
  loadGrid = true;

  constructor(private sharedService: SharedService, private cdRef: ChangeDetectorRef) {
    this.context = {
      thisComponent: this
    }
    this.frameworkComponents = {
      actionColumnRendererComponent: ActionColumnRendererComponent,
      formatDateRenderer: CustomDateRenderer,
      formatDateTimeRenderer: CustomDateTimeRenderer,
      iconFormatRendererComponent: IconFormatRendererComponent,
      customOverlayComponent: Overlay,
      numericEditor: NumericEditorComponent,
      validationMessageComponent: ValidationMessageComponent,
      colorCellRenderer: ColorCellRenderer,
      dateEditorComponent: DateEditorComponent,
      toggleEditorComponent: ToggleEditorComponent,
      clearFilterHeader: ClearFilterHeader
    };
    this.noRowsOverlayComponent = 'customOverlayComponent';
    this.noRowsOverlayComponentParams = {
      noRowsMessageFunc: function () {
        return {
          blankTemplateFlag: '',
        };
      },
    };
    this.overlayLoadingTemplate = LoanAssignmentLoadingTemplate;
  }

  ngOnInit(): void {
    this.subscription$ = this.sharedService.tabChange.subscribe(() => {
      if (this.grid) {
        this.grid.api.paginationGoToPage(0);
      }
    })
    this.subscription$ = this.sharedService.gridDataloaded.subscribe((params) => {
      if (this.grid) {
        this.grid.api.setRowData(params);
      }
    });
    this.subscription$ = this.sharedService.loadGrid.subscribe((params) => {
      this.loadGrid = params.isLoad;
    });
    this.setGridDefaults();
  }

  ngAfterViewInit(): void {
    this.ngOnChanges();
    this.grid.gridOptions.context = {
      thisComponent: this,
    };
  }

  ngOnChanges() {
    if (typeof this.grid !== 'undefined') {
      this.grid.api.setSideBarVisible(!this.suppressActionPrivilege);
      if (this.isRefresh) {
        this.grid.api.setRowData(this.rowData);
        this.grid.gridOptions.api.setFilterModel(this.currentFilter);
      }
      else {
        this.getColumnDefs(this.columnDefs);
        this.grid.api.setColumnDefs(null);
        this.grid.api.setColumnDefs(this.columnfilterdDefs);
        this.grid.api.setRowData(this.rowData);
        this.grid.gridOptions.api.setFilterModel(this.currentFilter);
      }
      if (this.failureMoveLoansIdx.length > 0) {
        this.failureMoveLoansIdx.forEach((index) => {
          this.gridApi.forEachNode(node => { if (node.rowIndex === index) { node.setSelected(true); } });
        });
        this.failureMoveLoansIdx = [];
      }
      this.setSelected();
    }
  }

  setFilterModel(model) {
    this.currentFilter = model;
  }

  onGridReady(event: any) {
    this.gridApi = event.api;
    this.setLoadingTemplate();
    this.grid.gridOptions.accentedSort = true;
    this.gridReady.emit();
    this.grid.api.sizeColumnsToFit();
  }

  onFirstDataRendered(params) {
    this.loadGrid = false;
  }

  setBlankTemplate() {
    const rowCount = this.grid.api.getDisplayedRowCount();
    if (rowCount == 0) {
      const params = this.grid.gridOptions.context;
      if (typeof params !== 'undefined' && this.rowData.length <= 0) {
        const params = this.grid.gridOptions.context;
        this.noOverlay.emit();
        this.grid.api.setSideBarVisible(false);
      }
      else if (rowCount == 0 && typeof params !== 'undefined' && this.rowData.length > 0) {
        this.grid.gridOptions.noRowsOverlayComponentParams = {
          noRowsMessageFunc: function () {
            return {
              blankTemplateFlag: 'noRecords',
            };
          }
        };
      }
      this.cdRef.detectChanges();
      this.grid.api.showNoRowsOverlay();
      this.blankTemplate = '';
    } else {
      this.grid.api.setSideBarVisible(!this.suppressActionPrivilege);
      this.grid.api.hideOverlay();
    }
  }

  onModelUpdated(e) {
    if (this.loadGrid) {
      this.setLoadingTemplate()
    } else {
      this.setBlankTemplate();
    }
  }

  showloadingOverLay() {
    this.grid.gridOptions.api.showLoadingOverlay();
  }
  getGridApi() {
    return this.grid.gridOptions.api;
  }

  setLoadingTemplate() {
    if (this.gridApi && this.gridApi.rowModel.rowsToDisplay.length == 0) {
      this.gridApi.showLoadingOverlay();
    }
    if (this.gridApi && this.gridApi.rowModel.rowsToDisplay.length > 0) {
      this.gridApi.hideOverlay();
    }
  }
  getGridColumnDefs()
  {
    return this.grid.api.getColumnDefs()
  }
  getColumnDefs(tableColumns: any) {
    this.columnfilterdDefs = [];
    if (tableColumns && tableColumns.length > 0) {
      tableColumns.forEach((tableItem: any) => {
        let colDefs;
        if (tableItem.filter === 'agDateColumnFilter') {
          colDefs = this.setColDateFilterDefs(tableItem);
        } else {
          colDefs = { ...tableItem };
        }
        if (typeof colDefs !== 'undefined')
          this.columnfilterdDefs.push(colDefs);
      });
    }
  }

  setColDateFilterDefs(tableItem: any) {
    let colFilterDefs = {
      ...tableItem,
      filterParams: {
        filterOptions:
          tableItem.filterOptions === 'equalsWithNull'
            ? [
              'equals',
              {
                displayKey: 'equalsWithNulls',
                displayName: 'Equals (with Nulls)',
                test: function (filterValue: any, cellValue: any) {
                  if (cellValue == null) return true;
                  const parts = cellValue.split('/');
                  const cellDate = new Date(
                    Number(parts[2]),
                    Number(parts[1] - 1),
                    Number(parts[0])
                  );
                  return cellDate.getTime() === filterValue.getTime();
                },
              },
            ]
            : '',
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          var dateAsString = moment(cellValue).format('MM/DD/YYYY');
          if (cellValue == null) {
            return 0;
          }
          var dateParts = dateAsString.split('/');
          var year = Number(dateParts[2]);
          var month = Number(dateParts[0]) - 1;
          var day = Number(dateParts[1]);
          var cellDate = new Date(year, month, day);

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
        browserDatePicker: true,
      }
    };
    return colFilterDefs;
  }

  onRowSelected(_event: any) {
    this.rowSelectionEvent.emit(this.grid.api.getSelectedRows());
    this.selectionChanged.emit(this.grid.api.getSelectedNodes());
  }

  onFilterChanged(event: any) {
    this.filterChangeEvent.emit(this.grid.api.getFilterModel());
  }

  resetGridHeight() {
    this.grid.api.setDomLayout("autoHeight");
    this.grid.api.resetRowHeights();
  }

  setSelected() {
    if (this.selectedIndices.length > 0) {
      this.gridApi.forEachNode(node => {
        if (this.selectedIndices.includes(node.id)) {
          node.setSelected(true);
        }
      });
    }
  }

  getColumnState() {
    return this.grid.columnApi.getColumnState();
  }

  isUserPreferenceChanged() {
    return this.sharedService.userPreferenceChanged;
  }

  onSortChanged() {
    this.changeUserPreference(true);
  }

  onColumnRowGroupChanged() {
    this.changeUserPreference(true);
  }

  onColumnVisible() {
    this.changeUserPreference(true);
  }

  onColumnMoved() {
    this.changeUserPreference(true);
  }

  changeUserPreference(value) {
    this.sharedService.userPreferenceChanged = value;
  }

  onColumnEverythingChanged() {
    
      this.changeUserPreference(this.sharedService.userPreferenceChanged);   
    
  }

  redrawRows() {
    this.grid.api.redrawRows();
  }

  onCellEditingStopped(event) {
    this.cellEditStopped.emit(event);
    this.setSelected();
  }

  onRowEditingStopped(params) {
    this.rowEditStopped.emit(params);
  }

  onRowEditingStarted(params) {
    this.rowEditStarted.emit(params);
  }

  refreshActionColumn(params) {
    this.grid.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node ? params.node : params],
      force: true
    });
    this.grid.api.redrawRows();
  }

  setGridDefaults() {
    this.sideBar = !this.suppressActionPrivilege && !this.suppressFilter ? {
      toolPanels: [
        'filters',
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressSyncLayoutWithGrid: true,
            suppressColumnSelectAll: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
          },
        },
      ]
    } : false;
    this.defaultColDef = {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: !this.suppressActionPrivilege,
      resizable: true,
      filter: true,
      autoHeight: true,
      lockPinned: true,
      suppressMenu: this.suppressActionPrivilege,
      suppressMovable: this.suppressActionPrivilege
    };
  }

  setColumnDefs(colDef) {
    this.grid.api.setColumnDefs(colDef);
  }

  onCellEditingStarted(event) {
    this.cellEditStarted.emit(event);
  }

  reSizeToFit() {
    setTimeout(() => {
      if (this.columnDefs.length < 8) {
        this.grid.api.sizeColumnsToFit();
      }
    }, 500)
  }

  startEditing(params, rowIndex?) {
    const colkey = params.columnApi.getDisplayedCenterColumns()[0].colId;
    this.grid.api.startEditingCell({
      rowIndex: rowIndex >= 0 ? rowIndex : params.node.rowIndex,
      colKey: colkey
    });
  }

  startEditingCertainCells(params, cell, rowIndex?) {
    this.grid.api.startEditingCell({
      rowIndex: rowIndex >= 0 ? rowIndex : params.node.rowIndex,
      colKey: cell
    });
  }

  getLastDisplayedRow() {
    return this.grid.api.getDisplayedRowAtIndex(this.grid.api.getLastDisplayedRow());
  }

  stopEdit() {
    this.grid.api.stopEditing();
  }

  gotoLastPage() {
    // this.gridApi.forEachLeafNode(x=>x.)
    this.grid.api.paginationGoToLastPage();
  }

  startEditingRow(params) {
    this.grid.api.startEditingCell(params);
  }

  suppressEditOnCellClick(status) {
    this.grid.gridOptions.suppressClickEdit = status;
  }

  destroy() {
    this.grid.gridOptions.api.destroy();
  }

  setRowData(params) {
    this.grid.api.setRowData(params);
    
  }

  onSelectionChanged(event) {
    this.selectionChanged.emit(event);
  }

  getSelectedNodes() {
    return this.grid.api.getSelectedNodes();
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

}
