import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  AuthHelperService } from '@wlms-web/data-access';

@Component({
  selector: 'wlms-exit-page',
  templateUrl: './exit-page.component.html',
  styleUrls: ['./exit-page.component.scss']
})
export class ExitPageComponent implements OnInit {

  exceptionType: string;
  showAccessDenied: boolean;
  ServershowAccessDenied: boolean;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public authHelperService: AuthHelperService
  ) {}
  ngOnInit(): void {
    this.authHelperService.stopTimer();
    this.route.params.subscribe((params) => {
      this.exceptionType = params['type'];
    });
  }

  relogin() {
    this.router.navigate(['/']);
  }
}
