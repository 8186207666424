import { Component } from '@angular/core';
import { ICellRendererParams, IHeaderParams } from 'ag-grid-community';
import * as moment from 'moment';
import { AgRendererComponent, IHeaderAngularComp,  } from 'ag-grid-angular';
import { SharedService, ColourCodes } from '@wlms-web/utils';

@Component({
  template: `{{ formatDate() }}`,
})
export class CustomDateRenderer implements AgRendererComponent {
  public params: any;

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public formatDate(): any {
    let momentDate = this.params.value
      ? moment(new Date(this.params.value))
      : null;
    return momentDate && momentDate.isValid()
      ? momentDate.format('MM/DD/YYYY')
      : '';
  }
}

@Component({
  template: `{{ formatDate() }}`,
})
export class CustomDateTimeRenderer implements AgRendererComponent {
  public params: any;

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public formatDate(): any {
    let momentDate = this.params.value
      ? moment(new Date(this.params.value))
      : null;
    return momentDate && momentDate.isValid()
      ? momentDate.format('MM/DD/YYYY hh:mm:ss a')
      : '';
  }
}

@Component({
  template: `<div>
      <i class="icon-icons-_-folder" *ngIf="(params?.data?.hierarchy?.length === 1||
        (params?.data?.WarehouseLineTrancheId>0&&params?.node?.allLeafChildren?.length>1))"></i>
      <i class="icon-file" *ngIf="(params?.data?.hierarchy?.length === 2||
        (params?.data?.WarehouseLineTrancheId>0&&params?.node?.allLeafChildren?.length<=1))"></i>
  </div>`,
})
export class IconFormatRendererComponent {

  constructor() { }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
}

@Component({
  template: `
  <ng-template #tipContent>
    <ng-container *ngFor="let error of erroList">
        {{error}}
    </ng-container>
  </ng-template>
  <span *ngIf="erroList.length>0" [tooltip]="tipContent" placement="right" container="body">
    <i class="icon-alert">
      <span class="app-c-blue-circle">
  </span>
</i>
</span>
  `,
  styles: ['.column-span {  margin-left: 10px;}']
})
export class ValidationMessageComponent {

  constructor(private sharedService: SharedService) { }
  erroList: any = [];
  src: any;
  title: any;
  agInit(params: any) {
    this.getErrorList(params);
  }

  getErrorList(params) {
    this.erroList = [];
    // call validator function that return error messages
    return this.erroList;
  }

}

@Component({
  host: { class: 'color-tag-renderer' },
  selector: 'medal-component',
  template: `
    <div class="color-wrapper" *ngIf="colors">
      <div  *ngFor="let color of colors" 
        class="color-item"
        [ngStyle]="{ background: color?.colorCode }"
        container="body"
        tooltip="{{color?.name}}"
        placement="right"
      ></div>
    </div>
  `,
})
export class ColorCellRenderer implements AgRendererComponent {
  colors: any;

  agInit(params: any): void {
    this.colors = this.getColorCode(params);
  }

  getColorCode(params) {
    const color = [];
    if (params.data && params.data.ManualWireInstructions && params.data.ManualWireInstructions.toUpperCase() == "YES") {
      color.push({colorCode:ColourCodes.ManualWireInstruction,
    name:'Manual Wire Instruction'});
    }
    if (params.data && params.data.isException) {
      color.push({colorCode:ColourCodes.Exception,
        name:'Exception'});
    }
    if (params.data && params.data.treasuryFunding.isReviewed) {
      color.push({colorCode:ColourCodes.IsReviewed,
        name:'Reviewed'});
    }
    if (params.data && params.data.DoesFurtherCreditExist) {
      color.push({colorCode:ColourCodes.DoesFurtherCreditExist,
        name:'Further Credit Instruction'});
    }
    return color.length > 0 ? color : null;
  }

  refresh(params: any): boolean {
    return false;
  }
}

@Component({
  template: `
    <div (click)="onClearFilters()" title="Clear all filters" class="u-clickable">
      <i class="fa fa-filter"></i>
    </div>
  `
})
export class ClearFilterHeader implements IHeaderAngularComp {
  params: any;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  onClearFilters() {
    this.params.api.setFilterModel(null);
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }
}
