import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ExitPageComponent } from './exit-page/exit-page.component';
import { MaterialModule } from '@wlms-web/material'

@NgModule({
  imports: [CommonModule,
    MaterialModule],
  declarations: [LoginComponent, ExitPageComponent],
})
export class FeaturesLoginModule { }
