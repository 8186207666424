<div class="app-l-page-content app-l-full-screen">
    <div class="app-l-access-denied">
        <div class="app-l-content-data">
            <div class="app-l-content-design">
                <div class="app-l-content-text">
                    <h4 class="app-l-no-access-head">Oops!</h4>
                    <h5 *ngIf="exceptionType == 'UnAuthorized'" class="app-l-no-access-text">
                        You are not authorized to view this page!
                    </h5>
                    <h5 *ngIf="exceptionType == 'Exception'" class="app-l-no-access-text">
                        Sorry, an error has occured, Requested page not found!
                    </h5>
                    <div class="app-l-btn-wrap">
                        <div class="app-l-btn-group">
                            <span class="app-l-btn-holder">
                <button
                  (click)="relogin()"
                  class="app-c-btn app-c-btn--primary app-c-rounded-btn"
                >
                  <ng-container  *ngIf="exceptionType == 'UnAuthorized'">Back To Home</ng-container>
                  <ng-container  *ngIf="exceptionType == 'Exception'">Try Login Again</ng-container>
                </button>
              </span>
                        </div>
                    </div>
                </div>

                <div class="app-l-content-graphic pt-2">
                    <img src="../assets/images/access-denied.png" alt="no access" />
                </div>
            </div>
        </div>
    </div>
</div>