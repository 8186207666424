import { SharedService } from './../services/shared.service';
import { Input, AfterViewInit, Directive, ViewContainerRef, TemplateRef } from '@angular/core';

@Directive({
  selector: '[wlmsIfPrivilege]'
})
export class IfPrivilegeDirective implements AfterViewInit {
  @Input() public wlmsIfPrivilege: Array<string>;

  constructor(private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private sharedService: SharedService) {
  }

  ngAfterViewInit(): void {
    if (this.sharedService.isAuthUser(this.wlmsIfPrivilege[0])) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
