<div
  *ngIf="params.data"
  class="app-l-wrapper"
  style="display: flex; justify-content: flex-end; height: 100%"
>

<button
    *ngIf="contentVisibility.copyContract"
    class="app-c-btn app-c-btn--icon app-c-btn--icon-30px app-c-transparent"
    (click)="copyContract()"
  >
    <i class="icon-copy_icon">
      <span class="app-c-blue-circle"></span>
    </i>
  </button>

  <button
  *ngIf="contentVisibility.copyTranche"
  class="app-c-btn app-c-btn--icon app-c-btn--icon-30px app-c-transparent"
  (click)="copyTranche()"
>
  <i class="icon-copy_icon">
    <span class="app-c-blue-circle"></span>
  </i>
</button>

  <button
    *ngIf="contentVisibility.infoIcon"
    class="app-c-btn app-c-btn--icon app-c-btn--icon-30px app-c-transparent"
    (click)="showInfoPanel()"
  >
    <i class="icon-info">
      <span class="app-c-blue-circle"></span>
    </i>
  </button>
  <button
    *ngIf="contentVisibility.add && !params.data.isEditEnabled"
    class="app-c-btn app-c-btn--icon app-c-btn--icon-30px app-c-transparent"
    (click)="add()"
  >
    <i class="icon-plus">
      <span class="app-c-blue-circle"></span>
    </i>
  </button>
  <button
    *ngIf="contentVisibility.edit && !params.data.isEditEnabled"
    class="app-c-btn app-c-btn--icon app-c-btn--icon-30px app-c-transparent"
    (click)="edit()"
  >
    <i class="icon-edit">
      <span class="app-c-blue-circle"></span>
    </i>
  </button>
  <button
    *ngIf="contentVisibility.delete"
    class="app-c-btn app-c-btn--icon app-c-btn--icon-30px app-c-transparent"
    (click)="delete()"
  >
    <i class="icon-delete">
      <span class="app-c-blue-circle"></span>
    </i>
  </button>
  <button
    *ngIf="params.data.isEditEnabled"
    class="app-c-btn app-c-btn--icon app-c-btn--icon-30px app-c-transparent"
    (click)="save()"
  >
    <i class="icon-tick">
      <span class="app-c-blue-circle"></span>
    </i>
  </button>
  <button
    *ngIf="params.data.isEditEnabled"
    class="app-c-btn app-c-btn--icon app-c-btn--icon-30px app-c-transparent"
    (click)="undo()"
  >
    <i class="icon-undo">
      <span class="app-c-blue-circle"></span>
    </i>
  </button>
  
  <button
    *ngIf="contentVisibility.empowerIcon"
    class="app-c-btn app-c-btn--icon app-c-btn--icon-30px app-c-transparent"
   
  >
    <i class="icon-empower" *ngIf="!params?.data?.networkLocked">
      <span class="app-c-blue-circle"></span>
    </i>
    <i class="icon-empower-locked " *ngIf="params?.data?.networkLocked"
      tooltip ="Locked By : {{params?.data?.lockedBy}}"  
      placement="bottom"
      container="body"
      containerClass="multiline-tooltip" >
      <span class="app-c-blue-circle"></span>
    </i>
  </button>
  <ng-container *ngIf="contentVisibility.dropdown">
    <span class="app-c-line">|</span>
    <wlms-warehouseline-dropdown
      [buttonGroup]="buttonGroup"
      [buttonLabel]="params.data ? selectedAction?.name : ''"
      [containerClass]="containerClass"
      [defaultButton]="params.data ? selectedAction : null"
      [disableDropdown]="params.data ? disableDropdown : null"
      [changeButtonLabel]="true"
      (optionSelected)="onWarehouselineSelected($event)"
      >
    </wlms-warehouseline-dropdown>
  </ng-container>
</div>
