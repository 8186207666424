import { Component } from '@angular/core';
import { SnackbarConfig } from '@wlms-web/utils';
import { AgEditorComponent } from 'ag-grid-angular';
import * as moment from 'moment';
import { UiHelperService } from './../../../services/ui-helper.service';

@Component({
  selector: 'wlms-date-editor',
  template: `
  <div class="row">
  <div class="col-xs-12 col-12 col-md-12 form-group" style="margin-top:4px">
    <input #input type="text" [(ngModel)]="selectedDate"
           placeholder="Datepicker"
           class="form-control"
           [minDate]="dateBoundary?.minDate"
           [maxDate]="dateBoundary?.maxDate"
           [bsConfig]="bsConfig"
           
           bsDatepicker>
  </div>
</div>`,
})
export class DateEditorComponent implements AgEditorComponent {
  params: any;
  selectedDate: Date = new Date();
  bsConfig = { containerClass: 'theme-dark-blue', showClearButton: true, clearPosition: 'right' };
  minDate: Date;
  dateBoundary: any

  constructor(private uiHelperService: UiHelperService) {

  }

  agInit(params: any): void {
    this.dateBoundary = { ...this.uiHelperService.dateBoundary };
    this.params = params;
    this.minDate = new Date();
    if (params.value) {
      this.selectedDate = new Date(params.value);
    }
  }

  getValue(): any {
    return this.selectedDate && Date.prototype.toString.call(this.selectedDate) != 'Invalid Date' ?
      this.selectedDate :
      this.params.value ? this.params.value : '';
  }

 

  ngOnDestroy() {
  }
}
