import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@ldsm/utils';
import { ConfigService, ApiConfig } from '@wlms-web/utils';

@Injectable({
  providedIn: 'root'
})
export class ExportHistoryService  extends BaseApiService {

  constructor(protected app: ConfigService, protected http: HttpClient) {
    super(
      http,
      app.getAppConfiguration().apiBaseUrl,
      app.getAppConfiguration().apiNamespace,
      ''
    );
  }

  getExportHistory() {
    return this.get('ExportHistory/GetAllExportLoanForGrid', ApiConfig.DISABLELOANCACHE);
  }
  getWarehouseline() {
    return this.get('WarehouseLine/GetWarehouseLineDetailsForLoanQueue', ApiConfig.DISABLEGETTIMECACHE);
  }
  assignAndExportLoan(request) {
    return this.post('Export/Empower/AssignandExport', request);
  }

  saveUserPreferences(request) {
    return this.post('user/SaveUserPreference', request);
  }

  getUserPreferences() {
    return this.get('user/GetPreference/', ApiConfig.DISABLELOANCACHE);
  }

  getColumnMaster(id) {
    return this.get('user/GetUserPrefernceColumnMaster/' + id);
  }

  ResetUserPreference(request) {
    return this.post('user/ResetUserPreference', request);
  }
}
