import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserStorageService {

  constructor() {}

  getLocalStorageValue(property: string): any {
    const item = localStorage.getItem(property);
    return item ? JSON.parse(item) : null;
  }

  setLocalStorageValue(property: string, value: any) {
    localStorage.setItem(property, JSON.stringify(value));
  }

  removeItemFromLocalStorage(property: string) {
    localStorage.removeItem(property);
  }

  clearLocalStorage() {
    localStorage.clear();
  }
  getSessionStorageValue(property: string): any {
    const item = sessionStorage.getItem(property);
    return item ? JSON.parse(item) : null;
  }

  setSessionStorageValue(property: string, value: any) {
    sessionStorage.setItem(property, JSON.stringify(value));
  }

  removeSessionStorageItem(property: string) {
    sessionStorage.removeItem(property);
  }
  
  clearSessionStorage() {
    sessionStorage.clear();
  }
}
