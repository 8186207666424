import { ColourCodes } from '../constants/data-constants'
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import * as moment from 'moment';
import { LoanUpdateStatus } from 'libs/utils/src/lib/constants/data-constants';
export class CustomSorting {
  static sortFloat = (valueA: any, valueB: any): any => {
    return parseFloat(valueA) - parseFloat(valueB);
  }
}

export class CellClassRules {
  static varianceHighlight = (params: any) => {
    if (params.data && params.data.wireAmount > (params.data.loanAmount + params.data.loanAmount * 0.1) ||
      params.data && params.data.wireAmount < (params.data.loanAmount - params.data.loanAmount * 0.1)) {
      return { backgroundColor: ColourCodes.VarianceWithinThreshold };
    }
    return null;
  }
}

export class CurrencyFormatter {
  static setCurrencyFormat = (params: any) => {
    if (params && params.value >= 0) {
      return formatCurrency(
        params.value,
        'en-US',
        getCurrencySymbol('USD', 'wide'),
        'USD'
      )
    }
    return null;
  }
}

export class PercentageFormatter {
  static setPercentageFormat = (params: any) => {
    if (params && params.value) {
      return params.value + ' %';
    }
    return null;
  }
}


export class TimeFormattor {
  static setDateFormattor = (params: any) => {
    if (params && params.value) {
      const dt = new Date(new Date().toDateString() + ' ' + params.value)
      return ((dt.getHours() % 12) || 12) + ':' + (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes() + ' '
        + (dt.getHours() >= 12 ? 'pm PST' : 'am PST');
    }
    return null;
  }
}

export class DateFormattor {
  static setDateFormattor = (params: any) => {
    if (params && params.value) {
      const momentDate = params.value
        ? moment(new Date(params.value))
        : null;
      return momentDate && momentDate.isValid()
        ? momentDate.format('MM/DD/YYYY')
        : '';
    }
    return null;
  }
  static dateComparator(date1: string, date2: string): number {
    if(date1==null || date1=='')
    {
      return -1;
    }
    return new Date(date1) < new Date(date2) ? -1 : 1;
  }
}

export class ConditionalEditor {
  static setConditionalEditor = (params: any) => {
    if (params && params.data && (params.data.hierarchy.length == 1 || params.data.WarehouseLineDailyCapId < 0)) {
      return true;
    }
    return false;
  }
  static setAdvanceDateEditor = (params: any) => {
    if (params && params.data && (params.data.treasuryFunding.loanFundingUpdateStatus==LoanUpdateStatus.NotStarted)) {
      return true;
    }
    return false;
  }
}



