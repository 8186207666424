import { Component } from '@angular/core';
import { BrowserStorageService, StorageKey } from '@wlms-web/utils'

@Component({
  selector: 'wlms-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'wlms-web';
  isLoggedIn = false;


  constructor(private browserStorageService: BrowserStorageService) {
    // const loginDetails = this.browserStorageService.getLocalStorageValue(
    //   StorageKey.LOGININFO
    // );
    // this.isLoggedIn = loginDetails?loginDetails.IsSuccess:false;
  }
}
