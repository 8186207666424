import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WarehouseLinesService } from '../services/whl.service'
import { SharedService, WarehouseLineDetails, NoRowsOverlayFlags, ScreenActionPrivileges } from '@wlms-web/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { WarehouseLineDetail } from '../whl-details/whl-details.model';

@Component({
  selector: 'wlms-whl-details',
  templateUrl: './whl-details.component.html',
  styleUrls: ['./whl-details.component.scss']
})
export class WarehouselinedetailsComponent implements OnInit {

  warehouse: any;
  allData = [];
  tabList: any = [];
  subscription$: Subscription;
  selectedTab = 0;
  warehouselinesrc: string;
  warehouseLineDetail: WarehouseLineDetail;
  warehouselinename: any;
  rowData = [];
  columnDefs = [];
  groupDefaultExpanded: Number;
  autoGroupColumnDef: any;
  getDataPath: any;
  currentTabIndex = 0;
  noRowsOverlayFlags = { ...NoRowsOverlayFlags };
  screenActionPrivileges = ScreenActionPrivileges;
  suppressActionPrivilege = false;
  suppressRightClickPrivilege = false;

  constructor(
    private warehouselinesservice: WarehouseLinesService,
    private sharedService: SharedService,
    private acitivateRouter: ActivatedRoute,
    private router: Router) {
    this.warehouseLineDetail = new WarehouseLineDetail();
  }

  ngOnInit(): void {
    this.warehouse = JSON.parse(localStorage.getItem('currentWarehouse'));
    this.warehouselinename = this.warehouse.WarehouseLineName;
    this.getWarehouselineDetailsData();
    const warehouseline = [...this.sharedService.warehouseLines].filter(x => x.code != null);
    const basePath = '/assets/images/'
    const element = warehouseline.filter(x => x.name.toUpperCase() == this.warehouselinename.toUpperCase());
    if (element.length > 0) {
      this.warehouselinesrc = basePath + element[0].icon;
    }
    this.setTabLevelPrivileges()
    this.subscription$ = this.sharedService.tabChange.subscribe((response: any) => {
      this.selectedTab = response.index;
    });
  }

  // Set privileges
  setTabLevelPrivileges() {
    this.tabList = [...WarehouseLineDetails].filter((x: any) => this.sharedService.isAuthUser(x.privilegeKey));
    this.suppressRightClickPrivilege = !this.sharedService.isAuthUser(this.tabList[this.selectedTab].suppressRightClickPrivilege);
    this.suppressActionPrivilege = !this.sharedService.isAuthUser(this.tabList[this.selectedTab].suppressActionPrivilege);
  }

  getWarehouselineDetailsData() {
    return this.warehouselinesservice.getWarehouseLineDetailsByWarehouseName(this.warehouselinename)
      .subscribe(response => {
        this.warehouseLineDetail = response['details'];
      })
  }

  getCurrentTabData(tabIndex) {
    let currentTabData: any = [];
    if (tabIndex == 0) {
      currentTabData = this.allData;
    } else {
      currentTabData = this.allData.filter((element: any) => {
        if (element.WarehouseLineDetails)
          return element.WarehouseLineDetails.toUpperCase() == this.tabList[tabIndex].name.toUpperCase();
      });
    }
    return currentTabData;
  }

  goback() {
    this.router.navigateByUrl('/warehouselines');
  }

  ngOnDestroy() {
    this.subscription$ ? this.subscription$.unsubscribe() : '';
  }
}
