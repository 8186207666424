import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@wlms-web/utils';
import { BaseApiService } from '@ldsm/utils';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseApiService {

  constructor(protected app: ConfigService, protected http: HttpClient) {
    super(
      http,
      app.getAppConfiguration().apiBaseUrl,
      app.getAppConfiguration().apiNamespace,
      ''
    );
  }

  getLogin() {
    return this.get('Login/logininfo');
  }

  getUserProfile() {
    return this.get('UserProfile');
  }
}
