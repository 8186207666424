import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseApiService } from '@ldsm/utils';
import { ConfigService } from './../config/config.service';
import { HttpClient } from '@angular/common/http';
import { BrowserStorageService } from './browser-storage.service';
import { StorageKey, TimeLimits } from './../constants/data-constants';

@Injectable()
export class SharedService extends BaseApiService {

  tabChange = new Subject<any>();
  gridDataloaded = new Subject<any>();
  slidePanelOpen = new Subject<any>();
  datasourceUpdated = new Subject<any>();
  loadGrid = new Subject<any>();
  showDetailPage = new Subject<any>();
  modalDataLoaded = new Subject<any>();
  modalClosed = new Subject<any>();
  modalSaved = new Subject<any>();
  modalSubmitValid = true;
  dayStart:any = TimeLimits.dayStart;
  dayEnd : any;
  userPreferenceChanged = false;
  disableRestButton = false;
  loanData =[];
  warehouseLines = [];
  constructor(protected app: ConfigService,
    protected http: HttpClient,
    private browserStorageService: BrowserStorageService) {
    super(
      http,
      app.getAppConfiguration().apiBaseUrl,
      app.getAppConfiguration().apiNamespace,
      ''
    );
  }

  isAuthUser(param) {
    const userDetails = this.browserStorageService.getLocalStorageValue(StorageKey.AZURELOGGEDINUSER);
    return userDetails && userDetails.UserProfile && userDetails.UserProfile.Privileges.indexOf(param) != -1;
  }

  getUserProfile() {
    return this.get('UserProfile');
  }
}
