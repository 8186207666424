import { Routes } from '@angular/router';
import { LoanAssignmentComponent } from '@wlms-web/features/loans';
import { LoginComponent, ExitPageComponent } from '@wlms-web/features/login';
import { WarehouselinedetailsComponent, WarehouselineDetailGridviewComponent } from '@wlms-web/features/warehouse-lines';
import { AuthGuard, DeactivateGuard } from '@wlms-web/data-access';
import { ScreenActionPrivileges } from '@wlms-web/utils';
import { ExportHistoryComponent } from '@wlms-web/features/export-history';

export const APP_ROUTES: Routes = [
  // Routes without master-page or that do not need to be authenticated need to go first
  {
    path: '',
    component: LoginComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'exitPage/:type',
    component: ExitPageComponent,
  },
  {
    path: 'loan-assignment',
    component: LoanAssignmentComponent,
    canActivate: [AuthGuard],
    data: { privilegeKey: ScreenActionPrivileges.LoanAssignment }
  },
  {
    path: 'warehouselines',
    component: WarehouselineDetailGridviewComponent,
    canActivate: [AuthGuard],
    data: { privilegeKey: ScreenActionPrivileges.Warehouseline }
  },
  {
    path: 'warehouseline-details',
    component: WarehouselinedetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'export-history',
    component: ExportHistoryComponent,
    canActivate: [AuthGuard],
    data: { privilegeKey: ScreenActionPrivileges.ExportHistory }
  },
];
