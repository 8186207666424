import { AuthActions, AuthActionTypes } from './auth.actions';

export const authFeatureKey = 'auth';

export interface AuthFullState {
  readonly [authFeatureKey]: AuthState;
}

export interface AuthState {
  authResponse: any;
  loading: boolean;
  loaded: boolean;
  error: {};
}

export const initialState: AuthState = {
  authResponse: {},
  loading: false,
  loaded: true,
  error: {},
};

export function reducer(state = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.VerifyToken:
      return state;
    case AuthActionTypes.VerifyTokenSuccess:
      const userDetail = action.payload.result;
      if (userDetail && userDetail.IsValid) {
        return {
          ...state,
          authResponse: userDetail,
          loaded: true,
          loading: false,
        };
      }
      return state;
    case AuthActionTypes.VerifyTokenFailure:
      if (action.payload) {
        return { ...state, error: action.payload };
      } else {
        return state;
      }
    case AuthActionTypes.ReloadAuthState:
      const authState = action.payload.authState;
      if (authState && authState.IsValid) {
        return {
          ...state,
          authResponse: authState,
          loaded: true,
          loading: false,
        };
      }
      return state;
    default:
      return state;
  }
}
