import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '@ldsm/utils';
import { ConfigService } from '@wlms-web/utils';

@Injectable()
export class AuthService extends BaseApiService {
  constructor(protected app: ConfigService, protected http: HttpClient) {
    super(
      http,
      app.getAppConfiguration().apiBaseUrl,
      app.getAppConfiguration().apiNamespace,
      'Login/'
    );
  }

  verifyToken(authrequest: any) {
    return this.post('validate', authrequest);
  }

  refreshToken(token: string) {
    const refreshToken = { token: token };
    return this.put('refreshtoken', refreshToken);
  }
}
